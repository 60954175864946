import { inject, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'safeNumber',
  standalone: true,
})
export class SafeNumberPipe implements PipeTransform {
  private decimalPipe = inject(DecimalPipe);

  transform(value: any, args?: any): string {
    try {
      return this.decimalPipe.transform(value, args) ?? '';
    } catch (ex) {}
    return '';
  }
}
