import { Directive, Input, ViewContainerRef, ComponentRef, Injector, OnDestroy, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { BackdropComponent } from './backdrop.component';
import { BusyComponent } from './busy.component';

@Directive({
  selector: '[libSharedUiBusy]',
  standalone: true,
})
export class BusyDirective implements OnDestroy {
  private vcRef = inject(ViewContainerRef);
  private injector = inject(Injector);

  @Input()
  set libSharedUiBusy(payload: Promise<any> | Subscription | boolean | undefined | null) {
    this.destroyComponents();
    if (!payload) {
      return;
    }
    this.createComponents();
    if (typeof payload !== 'boolean') {
      this.watch(payload);
    }
  }

  private busyRef?: ComponentRef<BusyComponent>;
  private backdropRef?: ComponentRef<BackdropComponent>;

  ngOnDestroy() {
    this.destroyComponents();
  }

  private createComponents() {
    this.createBackdrop();
    this.createBusy();
  }

  private destroyComponents() {
    this.busyRef?.destroy();
    this.backdropRef?.destroy();
  }

  private createBackdrop() {
    this.backdropRef = this.vcRef.createComponent(BackdropComponent, { injector: this.injector });
  }

  private createBusy() {
    this.busyRef = this.vcRef.createComponent(BusyComponent, { injector: this.injector });
  }

  private watch(payload: Promise<any> | Subscription) {
    if (payload instanceof Promise) {
      payload.finally(() => this.destroyComponents());
      return;
    }
    payload.add(() => this.destroyComponents());
  }
}
