import { DestroyRef, Directive, HostListener, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs';

@Directive({
  selector: '[libSharedUiUppercase]',
  standalone: true,
})
export class UppercaseDirective implements OnInit {
  private ngControl = inject(NgControl, { self: true });
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.ngControl.valueChanges
      ?.pipe(debounceTime(0), distinctUntilChanged(), filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        const uppercaseValue = value.toUpperCase();
        if (uppercaseValue !== value) {
          this.ngControl.control?.setValue(value.toUpperCase(), { emitEvent: false });
        }
      });
  }

  @HostListener('input', ['$event.target'])
  public onInput(input: HTMLInputElement): void {
    const start = input.selectionStart;
    const end = input.selectionEnd;
    this.ngControl.control?.setValue(input.value.toUpperCase());
    input.setSelectionRange(start, end);
  }
}
