import { Directive, ElementRef, inject, input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ExportService } from '@mca/shared/util';

@Directive({
  selector: '[libSharedUiExportToCsv]',
  standalone: true,
})
export class ExportToCsvDirective {
  private messageService = inject(MessageService);
  private exportService = inject(ExportService);
  private el = inject(ElementRef);

  exportFileName = input('exported_data');

  public exportToCsv(): void {
    const tableElement = this.el.nativeElement.querySelector('table') as HTMLTableElement | null;
    if (!tableElement) {
      this.messageService.add({ severity: 'error', summary: 'No table element found' });
      return;
    }

    this.exportService.exportArrayDataToCsv(
      this.extractTableData(tableElement),
      this.extractTableHeaders(tableElement),
      `${this.exportFileName()}.csv`,
    );
  }

  private extractTableHeaders(tableElement: HTMLTableElement): string[] {
    const headerRow = tableElement.querySelector('thead tr');
    if (headerRow) {
      return Array.from(headerRow.children).map((th: Element) => (th as HTMLElement).innerText.trim()) as string[];
    }
    return [];
  }

  private extractTableData(tableElement: HTMLTableElement): string[][] {
    const dataRows = Array.from(tableElement.querySelectorAll('tbody tr'));
    return dataRows.map((row: Element) => Array.from(row.children).map((td: Element) => (td as HTMLElement).innerText.trim()) as string[]);
  }
}
