import { Component, input, output } from '@angular/core';
import { zoomInOutAnimation } from '@mca/shared/util';

@Component({
  selector: 'lib-shared-ui-submit-btn',
  templateUrl: './submit-btn.component.html',
  styleUrls: ['./submit-btn.component.scss'],
  animations: [zoomInOutAnimation],
  standalone: true,
})
export class SubmitBtnComponent {
  loading = input(false);
  disabled = input(false);
  type = input('primary');
  text = input('Save');
  autofocus = input(false);

  btnClick = output();

  onClick(event: any): void {
    if (!this.loading() && !this.disabled()) {
      this.btnClick.emit(event);
    }
  }
}
