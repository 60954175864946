import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngModel][EINNumber]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EINNumberDirective, multi: true }],
  standalone: true,
})
export class EINNumberDirective implements Validator {
  validate(c: AbstractControl) {
    return EINValidator()(c);
  }
}

export const EINValidator =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const isValid = !control.value || /^\d{2}-?\d{7}$/.test(control.value);

    return !isValid ? { einNumber: 'EIN: 9-digit with optional hyphen (XX-XXXXXXX)' } : null;
  };
