@if (opened()) {
  <p-overlayPanel #op styleClass="button-menu-overlay" [appendTo]="container()" (onHide)="opened.set(false)">
    <div (click)="op.hide()">
      <ng-content></ng-content>
    </div>
  </p-overlayPanel>
}
<button
  class="btn btn-primary button-menu-toggle dotted-menu text-nowrap"
  (click)="toggle($event)"
  [class.disabled]="disabled()"
  [disabled]="disabled()"
>
  @if (label()) {
    <span class="me-2">{{ label() }}</span>
  }
</button>
