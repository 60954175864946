import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { ApiService } from '@mca/shared/util';
import { finalize } from 'rxjs';

@Component({
  selector: 'lib-shared-ui-file-upload',
  templateUrl: './file-upload.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent {
  private apiService = inject(ApiService);

  uploadUrl = input('');
  label = input('Select file');

  file = signal<File | undefined>(undefined);
  loading = signal(false);
  percent = signal(0);

  onFileChange(event: Event) {
    const files = (event.target as HTMLInputElement).files ?? [];
    this.file.set(files[0]);
    this.percent.set(0);
  }

  upload() {
    const file = this.file() as File;
    const form = new FormData();
    form.append('file', file, file.name);
    this.loading.set(true);
    this.percent.set(0);
    this.apiService
      .uploadFile(this.uploadUrl(), form)
      .pipe(finalize(() => this.loading.set(false)))
      .subscribe(({ percent }) => this.percent.set(percent));
  }
}
