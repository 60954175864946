<button
  [disabled]="loading() || disabled()"
  type="button"
  class="btn submit btn-{{ type() }}"
  (click)="onClick($event)"
  [autofocus]="autofocus()"
>
  <div [@zoomInOut]="loading() === false ? 'in' : 'out'" class="label">
    <div #label>
      <ng-content></ng-content>
    </div>
    @if (label.childNodes.length === 0) {
      <div>{{ text() }}</div>
    }
  </div>
  <div [@zoomInOut]="loading() ? 'in' : 'out'" class="ld ld-ring ld-spin">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</button>
