import { Directive, HostListener, inject, input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[libSharedUiExternalUrl]',
  standalone: true,
})
export class ExternalUrlDirective {
  private router = inject(Router);

  params = input({ recType: '', recId: 0 });
  libSharedUiExternalUrl = input('');

  @HostListener('click') onClick() {
    this.openChangelog();
  }

  private openChangelog() {
    const link = this.router.serializeUrl(
      this.router.createUrlTree([this.libSharedUiExternalUrl()], {
        queryParams: this.params(),
      }),
    );
    window.open(link, '_blank');
  }
}
