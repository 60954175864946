import { ChangeDetectionStrategy, Component, input, model, viewChild } from '@angular/core';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'lib-shared-ui-buttons-menu',
  templateUrl: './buttons-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OverlayPanelModule],
})
export class ButtonsMenuComponent {
  label = input('');
  disabled = input(false);
  container = input<'body' | null>(null);

  private overlayPanel = viewChild(OverlayPanel);
  opened = model(false);

  toggle(event: Event) {
    this.opened.set(!this.opened());
    setTimeout(() => {
      if (this.opened()) {
        this.overlayPanel()?.show(event);
      } else {
        this.overlayPanel()?.hide();
      }
    });
  }
}
