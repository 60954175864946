<div class="d-flex align-items-center flex-nowrap">
  <label class="m-0 p-0">
    <input hidden type="file" name="file_upload" (change)="onFileChange($event)" />
    <span class="btn btn-primary">
      <i class="fa fa-upload"></i>
    </span>
  </label>
  @if (!file()) {
    <span class="text-dark ms-3">{{ label() }}</span>
  }
  @if (file()) {
    <span class="text-info ms-3 overflow-hidden">{{ file()?.name }}</span>
    <div class="ms-auto">
      @if (!loading() && percent() !== 100) {
        <button class="btn btn-success" type="button" (click)="upload()">
          <i class="fa fa-save"></i>
        </button>
      }
      @if (loading()) {
        <span class="text-info">{{ percent() + '%' }}</span>
      }
    </div>
  }
</div>
