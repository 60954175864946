import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardAccount',
  standalone: true,
})
export class CardAccountPipe implements PipeTransform {
  transform(value: any, args?: { secure: boolean }): string {
    if (!value) {
      return value;
    }
    if (value.length === 16) {
      if (args?.secure) {
        const visiblePart = value.substring(value.length - 4);
        return `**** **** **** ${visiblePart}`;
      }
      return value.match(/.{4}/g).join(' ');
    }
    // American Express card format
    if (value.length === 15) {
      if (args?.secure) {
        const visiblePart = value.substring(value.length - 5);
        return `**** ****** ${visiblePart}`;
      }
      return `${value.substring(0, 4)} ${value.substring(4, 10)} ${value.substring(10, 15)}`;
    }
    return value;
  }
}
