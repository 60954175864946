import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsMenuComponent } from './buttons-menu/buttons-menu.component';
import { UseUtcDirective } from './calendar/use-utc.directive';
import { FormsModule } from '@angular/forms';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { SubmitBtnComponent } from './submit-btn/submit-btn.component';
import { UppercaseDirective } from './form-directives/uppercase.directive';
import { EINNumberDirective } from './form-directives/ein-number.validator';
import { ValidationContainerDirective } from './form-directives/validation-container.directive';
import { SafeNumberPipe } from './pipes/safe-number-pipe.pipe';
import { ExternalUrlDirective } from './external-url/external-url.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { CardAccountPipe } from './pipes/card-account.pipe';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ExportToCsvDirective } from './table-directives/export-to-csv.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule,
    FileUploadComponent,
    CardAccountPipe,
    OverlayPanelModule,
    ButtonsMenuComponent,
    UseUtcDirective,
    FormErrorsComponent,
    SubmitBtnComponent,
    UppercaseDirective,
    EINNumberDirective,
    ValidationContainerDirective,
    ExternalUrlDirective,
    SafeNumberPipe,
    ExportToCsvDirective,
  ],
  exports: [
    ButtonsMenuComponent,
    UseUtcDirective,
    FormErrorsComponent,
    SubmitBtnComponent,
    UppercaseDirective,
    EINNumberDirective,
    ValidationContainerDirective,
    ExternalUrlDirective,
    SafeNumberPipe,
    FileUploadComponent,
    CardAccountPipe,
    ExportToCsvDirective,
  ],
  providers: [SafeNumberPipe],
})
export class SharedUiModule {}
