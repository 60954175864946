import { Directive, inject, input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, NgControl } from '@angular/forms';

/**
 * Allows to add separate controls into one container group, that can be used to check validity of all items
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngModel][validationContainer]',
  standalone: true,
})
export class ValidationContainerDirective implements OnInit, OnDestroy {
  private ngControl = inject(NgControl, { self: true });

  validationContainer = input.required<FormGroup>();
  name = input('');

  ngOnInit() {
    if (this.name() && this.ngControl.control) {
      this.validationContainer().addControl(this.name(), this.ngControl.control);
    } else {
      console.warn('validationContainer is set without named control');
    }
  }

  ngOnDestroy() {
    this.validationContainer().removeControl(this.name());
  }
}
