import { ChangeDetectionStrategy, Component } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';

const inactiveStyle = style({
  opacity: 0,
});
const timing = '.3s ease';

@Component({
  selector: 'lib-shared-ui-busy-backdrop',
  template: ` <div @fadeInOut class="lib-shared-ui-busy-backdrop"></div> `,
  styles: [
    `
      .lib-shared-ui-busy-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9001;
        background: #fff;
        opacity: 0.4;
      }
    `,
  ],
  animations: [
    trigger('fadeInOut', [transition(':enter', [inactiveStyle, animate(timing)]), transition(':leave', [animate(timing, inactiveStyle)])]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BackdropComponent {}
