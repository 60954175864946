import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  template: `<div class="lib-shared-ui-busy d-flex align-items-center justify-content-center"><div class="whirly-loader"></div></div>`,
  styles: [
    `
      .lib-shared-ui-busy {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 5rem;
        z-index: 9002;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BusyComponent {}
