import { AfterViewInit, Directive, HostListener, inject } from '@angular/core';
import { getDateAt } from '@mca/shared/util';
import { Calendar } from 'primeng/calendar';

/**
 * UTC option for p-calendar, displays same date in all timezones
 * https://github.com/primefaces/primeng/issues/5853#issuecomment-394696686
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[useUtc]',
  standalone: true,
})
export class UseUtcDirective implements AfterViewInit {
  private calendar = inject(Calendar, { self: true });

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this.fixInitialValue();
    });
  }

  @HostListener('onSelect', ['$event']) onSelect() {
    this.toMidnight();
  }

  @HostListener('onInput', ['$event']) onInput() {
    this.toMidnight();
  }

  // make sure that initial day is set by UTC timezone
  private fixInitialValue() {
    const value = this.calendar.value;
    // non-utc date from BE will be with shifted hours by timezone, otherwise it's fixed already
    if (!value || this.isMidNight(value)) {
      return;
    }
    this.calendar.value = getDateAt({
      baseDate: value,
      day: value.getUTCDate(),
      month: value.getUTCMonth(),
      year: value.getUTCFullYear(),
    });
    this.toMidnight();
  }

  // keep value at midnight in local timezone
  private toMidnight() {
    if (!this.calendar.value) {
      return;
    }
    const value = getDateAt({ baseDate: this.calendar.value, hour: 0, min: 0, sec: 0 });
    this.calendar.writeValue(value);
    this.calendar.updateModel(value);
  }

  private isMidNight(value: Date) {
    return !value.getHours() && !value.getMinutes() && !value.getSeconds();
  }
}
