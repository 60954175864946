import { Directive, effect, ElementRef, HostBinding, inject, input, OnDestroy, Renderer2 } from '@angular/core';
import { AnimationBuilder, AnimationPlayer, animate, animation, style, useAnimation } from '@angular/animations';

const expandVerticalAnimation = animation([animate('{{ time }} {{ easing }}')]);

const collapseVerticalAnimation = animation([
  style({ height: '*', minHeight: '*' }),
  animate('{{ time }} {{ easing }}', style({ height: 0, minHeight: 0 })),
]);

const expandHorizontalAnimation = animation([animate('{{ time }} {{ easing }}')]);

const collapseHorizontalAnimation = animation([animate('{{ time }} {{ easing }}')]);

/**
 * Simplified version of https://github.com/coreui/coreui-angular/blob/main/projects/coreui-angular/src/lib/collapse/collapse.directive.ts
 */
@Directive({
  selector: '[libSharedUiCollapse]',
  standalone: true,
})
export class CollapseDirective implements OnDestroy {
  private hostElement = inject(ElementRef);
  private renderer = inject(Renderer2);
  private animationBuilder = inject(AnimationBuilder);

  animate = input(true);
  horizontal = input(false);
  duration = input('350ms');
  transition = input('ease');
  libSharedUiCollapse = input(null as boolean | null);

  private collapsed: boolean | null = null;

  private player!: AnimationPlayer;
  private readonly host: HTMLElement;

  constructor() {
    this.host = this.hostElement.nativeElement;
    this.renderer.addClass(this.host, 'collapse');
    this.renderer.setStyle(this.host, 'display', 'none');
    effect(() => {
      if (this.libSharedUiCollapse() !== this.collapsed) {
        this.toggle(this.libSharedUiCollapse());
      }
      this.collapsed = this.libSharedUiCollapse();
    });
  }

  @HostBinding('class')
  get hostClasses(): any {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'collapse-horizontal': this.horizontal(),
    };
  }

  ngOnDestroy() {
    this.destroyPlayer();
  }

  toggle(collapsed = this.collapsed) {
    this.createPlayer(collapsed);
    this.player?.play();
  }

  destroyPlayer() {
    this.player?.destroy();
  }

  createPlayer(collapsed: boolean | null = this.collapsed) {
    if (this.player?.hasStarted()) {
      this.destroyPlayer();
    }

    if (!collapsed) {
      this.renderer.removeStyle(this.host, 'display');
    }

    const duration = this.animate() ? this.duration() : '0ms';

    const expandAnimation = this.horizontal() ? expandHorizontalAnimation : expandVerticalAnimation;
    const collapseAnimation = this.horizontal() ? collapseHorizontalAnimation : collapseVerticalAnimation;

    const dimension = this.horizontal() ? 'width' : 'height';
    const capitalizedDimension = dimension[0].toUpperCase() + dimension.slice(1);
    const scrollSizeParam = `scroll${capitalizedDimension}`;

    const animationFactory = this.animationBuilder.build(
      useAnimation(collapsed ? collapseAnimation : expandAnimation, { params: { time: duration, easing: this.transition() } }),
    );

    this.player = animationFactory.create(this.host);

    this.renderer.setStyle(this.host, dimension, collapsed ? `${this.host.getBoundingClientRect()[dimension]}px` : 0);

    this.player.onStart(() => {
      this.renderer.removeClass(this.host, 'collapse');
      this.renderer.addClass(this.host, 'collapsing');
      this.renderer.removeClass(this.host, 'show');
      if (collapsed) {
        this.renderer.setStyle(this.host, dimension, '');
      } else {
        this.renderer.setStyle(this.host, dimension, `${(this.host as any)[scrollSizeParam]}px`);
      }
    });
    this.player.onDone(() => {
      this.collapsed = collapsed;
      this.renderer.removeClass(this.host, 'collapsing');
      this.renderer.addClass(this.host, 'collapse');
      if (collapsed) {
        this.renderer.removeClass(this.host, 'show');
      } else {
        this.renderer.addClass(this.host, 'show');
        this.renderer.setStyle(this.host, dimension, '');
      }
    });
  }
}
